import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react';
import { MdCheck } from 'react-icons/md';
import { cn } from 'utils/cn';

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    data-testid="checkbox"
    ref={ref}
    className={cn(
      'focus-visible-visible:outline-0 relative h-4 w-4 rounded border border-neutral-400 bg-white ring-purple-700 ring-offset-2 transition-all focus-visible:outline-0 focus-visible:ring-2 dark:border-dark-400',
      className,
    )}
    {...props}
  />
));

// TODO: update styles when the issue is resolved (https://github.com/storybookjs/storybook/issues/24283, https://github.com/chromaui/storybook-addon-pseudo-states/issues/102)
const CheckboxIndicator = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Indicator>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Indicator
    ref={ref}
    className={cn(
      'absolute -left-px -top-px flex h-4 w-4 items-center justify-center rounded border border-purple-600 bg-purple-700 hover:bg-purple-800 dark:border-purple-600 dark:bg-purple-500 dark:hover:bg-purple-600',
      className,
    )}
    {...props}
  >
    <MdCheck className="text-white" data-testid="checkbox-icon" />
  </CheckboxPrimitive.Indicator>
));

export { Checkbox, CheckboxIndicator };
