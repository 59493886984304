import { Text } from 'components/typography/text';
import { Checkbox, CheckboxIndicator } from 'components/ui/checkbox';
import { Children } from 'react';
import { MdClose, MdKeyboardArrowDown } from 'react-icons/md';
import Select, { components, type MultiValue, type SingleValue } from 'react-select';
import { sendAnalytics } from 'utils/analytics/google';
import { cn } from 'utils/cn';

export type FilterItem = {
  value: string;
  count?: number;
};

export type FilterData = {
  name: string;
  multi?: boolean;
  items: Array<FilterItem>;
};

const styles = {
  option: () => ({}),
  control: () => ({}),
  menu: () => ({}),
  indicatorsContainer: () => ({}),
  valueContainer: () => ({}),
};

const classNames = {
  container: () => 'w-full flex-1',
  input: () => 'p-0 m-0 text-neutral-900 dark:text-dark-50',
  placeholder: () => 'text-neutral-600 dark:text-dark-300',
  option: (state: any) =>
    cn(
      'flex items-center gap-2 rounded-lg my-1 mx-2 p-2 w-auto cursor-pointer bg-white dark:bg-dark-800',
      'hover:bg-neutral-100 hover:dark:bg-dark-850 active:bg-neutral-100 active:dark:bg-dark-850',
      `lg:${!state.isSelected && state.isFocused ? 'bg-neutral-100' : 'bg-white'} dark:lg:${!state.isSelected && state.isFocused ? 'bg-dark-850' : 'bg-dark-800'}`,
    ),
  control: (state: any) =>
    cn(
      'flex min-h-[38px] items-center bg-white dark:bg-dark-800 rounded-lg border',
      'hover:cursor-pointer border-neutral-500 dark-border-dark-600',
      `${state.menuIsOpen ? 'border-purple-300 dark:border-purple-600 ring-4 ring-purple-100 dark:ring-purple-600' : 'border-neutral-300 dark:border-dark-700'}`,
    ),
  menu: () =>
    'absolute top-full w-full bg-white dark:bg-dark-800 border border-neutral-200 dark:border-dark-600 rounded-lg shadow my-2',
  indicatorsContainer: () => 'flex items-center shrink-0 text-neutral-600 dark:text-dark-300 text-xl',
  valueContainer: () => 'flex flex-1 items-center flex-wrap gap-2 overflow-hidden py-0.5 px-2',
};

const ClearIndicator = ({ ...props }: any) => (
  <components.ClearIndicator {...props}>
    <MdClose aria-hidden="true" focusable={false} />
  </components.ClearIndicator>
);

const DropdownIndicator = ({ ...props }: any) => (
  <components.DropdownIndicator {...props}>
    <MdKeyboardArrowDown aria-hidden="true" focusable={false} />
  </components.DropdownIndicator>
);

const ValueContainer = ({ children, ...props }: any) => {
  const { getValue, hasValue } = props;
  const { length } = getValue();

  return (
    <components.ValueContainer {...props}>
      {hasValue && (
        <>
          <Text className={`font-${length >= 1 ? 'medium' : 'normal'}`}>{props.selectProps.placeholder}</Text>
          {length === 1 && (
            <Text data-testid="selected-option" className="text-neutral-600 dark:text-dark-300">
              {getValue()[0].value}
            </Text>
          )}
          {length > 1 && <Text className="text-neutral-600 dark:text-dark-300">{`${length} selected`}</Text>}
        </>
      )}
      {Children.map(children, (child) => (!hasValue || child.type === components.Input ? child : null))}
    </components.ValueContainer>
  );
};

const CustomOption = ({ children, ...props }: any) => {
  const innerProps = { ...props.innerProps, 'aria-label': props.data.value };
  return (
    <components.Option {...props} innerProps={innerProps}>
      <Checkbox
        checked={props.isSelected}
        className={cn(
          !props.isSelected &&
            props.isFocused &&
            'border-purple-600 ring-2 ring-purple-700 ring-offset-2 dark:border-purple-600',
        )}
      >
        <CheckboxIndicator />
      </Checkbox>
      <Text className="text-sm">{props.data.value}</Text>
      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-neutral-200 text-xs font-medium text-neutral-900 dark:bg-dark-700 dark:text-dark-200">
        {props.data.count}
      </div>
    </components.Option>
  );
};

export type FilterProps = {
  filters: Array<FilterData>;
  currentFilter: Array<FilterData>;
  onChangeFilter: (value: Array<FilterData>) => void;
  labels: { title: string };
};

export const Filter = ({ filters, currentFilter, onChangeFilter, labels }: FilterProps) => {
  if (!filters || filters.length === 0) return null;

  const onChangeHandler = ({
    name,
    multi,
    value,
  }: {
    name: string;
    multi: boolean;
    value: SingleValue<FilterItem> | MultiValue<FilterItem>;
  }) => {
    const newFilterValue = [...currentFilter];
    const foundFilter = newFilterValue.some((item) => item.name === name);

    const filterValue = Array.isArray(value)
      ? [...value].pop()?.value?.toLowerCase()
      : (value as SingleValue<FilterItem>)?.value?.toLowerCase();
    if (filterValue) {
      sendAnalytics('apiFilter', {
        filterValue,
        filterName: name.toLowerCase(),
      });
    }

    let newValue: Array<FilterItem>;
    if (value) {
      newValue = Array.isArray(value) ? value : [value];
    } else {
      newValue = [];
    }

    // Add filter
    if (!foundFilter) {
      newFilterValue.push({ name, multi, items: newValue });
    }

    // Update filter
    if (foundFilter) {
      const filterIndex = newFilterValue.findIndex((item) => item.name === name);
      newFilterValue[filterIndex] = { name, multi, items: newValue };
    }

    onChangeFilter(newFilterValue.filter((filter) => filter.items.length > 0));
  };

  const customComponents = {
    ClearIndicator,
    DropdownIndicator,
    ValueContainer,
    Option: CustomOption,
    IndicatorSeparator: () => null,
  };

  return (
    <div data-testid="filter">
      <Text className="mr-4 text-sm font-semibold">{labels.title}</Text>
      <div className="mb-8 mt-2 flex w-full flex-1 flex-col flex-nowrap items-center justify-start gap-4 md:flex-row">
        {filters.map(({ name, multi = false, items }) => {
          const currentValue = currentFilter.find((item) => item.name === name)?.items;
          return (
            <Select
              aria-label={`Filter by ${name}`}
              key={name}
              hideSelectedOptions={false}
              options={items}
              isMulti={multi}
              onChange={(value) => onChangeHandler({ name, multi, value })}
              value={currentValue}
              placeholder={name}
              isClearable
              styles={styles}
              classNames={classNames}
              openMenuOnFocus
              openMenuOnClick
              components={customComponents}
            />
          );
        })}
      </div>
    </div>
  );
};
